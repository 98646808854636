/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://svcurihiyjb7xakwpbtwwicnqi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oee3oqkpandqfdpqzqvs2ib5hu",
    "aws_cognito_identity_pool_id": "us-west-2:2bda28c5-f74a-44ee-a127-9c8d8f86060d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_cDRSvQWcS",
    "aws_user_pools_web_client_id": "15nv7os5d5p1svk3fqp3s8e0dd",
    "oauth": {}
};


export default awsmobile;
